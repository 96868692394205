<template>
  <div style="padding:0 60px;">
    <!-- 收货人 -->
    <div style=" font-weight: 600;margin: 10px 0;">收货人信息</div>
    <div style="display: flex;align-items: center;justify-content: space-between; font-size: 12px;margin-bottom: 10px;">
      <div style="display: flex;align-items: center; font-size: 12px;">
        <el-button size="small" type="primary" style="margin-right: 20px;"
          @click="_ => (getAddr(), publishOnlineDialog.display())">选择收货人</el-button>
        <upload-excel-component size="small" type="primary" text="导入收货人" defaultSize :on-success="excelSuccess" />
        <div style="display: flex;align-items: center;">
          <a href="javascript:;" style="margin: 0 6px; cursor: pointer;" @click="downTemplate">下载收货人导入模版</a>
          <el-tooltip class="item" effect="light" placement="top">
            <div slot="content" style="max-width: 500px;">
              导入收货人注意事项：
              <br />
              1、单次导入收货人总行数不要超过200条
              <br />
              2、收货人姓名不要超过50个字，手机号请按正确格式和11位数字填写
              <br />
              3、详细地址需要包括省市区和具体地址，整体不要超过100个字，详细地址省市区请仔细核查，例如有没有写区/县，或者越过地级市直接写县级市，或者写高新区之类民政部没有的区，如果对地址库有疑问，请访问http://xzqh.mca.gov.cn/map查询
              <br />
              4、订单备注不要超过200字
            </div>
            <div style="font-size: 20px;" class="el-icon-warning-outline"></div>
          </el-tooltip>
        </div>
      </div>
      <div>
        <el-button v-show="information.data.length" size="small" type="danger" @click="batchDelete">批量删除</el-button>
      </div>
    </div>
    <div style="min-height: 220px;">
      <el-table v-show="information.data.length" @selection-change="selectChange" :data="information.data" border
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#fff',
          fontWeight: '400',
          color: '#333',
          padding: '3px 0'
        }" :cell-style="{ textAlign: 'center', color: '#333', padding: '3px 0' }" style="width: 100%" max-height="220">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="name" label="收货人姓名" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="手机号" width="160"></el-table-column>
        <el-table-column label="所在地区" :show-overflow-tooltip="true">
          <template slot-scope="{row}">
            <span>{{ (row.province || '') + (row.city || '') + (row.county || '') + (row.town || '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="详细地址" :show-overflow-tooltip="true">
          <template slot-scope="{row}">
            <span>{{ row.addr }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_remark" label="订单备注" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="caozuo" label="操作" width="160">
          <template slot-scope="scope">
            <el-button style="margin-left: 5px;" size="mini" type="danger" @click="detailAddr(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="information.data.length">注：勾选收货人仅用于批量删除收货人，提交订单时将使用列表中所有的收货人</div>
    </div>
    <!-- 商品 -->
    <div style=" font-weight: 600;margin: 10px 0;">商品列表</div>
    <el-table :data="tableData" border style="width: 100%" header-cell-class-name="headerStyle"
      :cell-class-name="cellStyle">
      <el-table-column label="商品图片" width="126" class-name="goods-cover-wrapper">
        <template slot-scope="{row}">
          <el-popover placement="right" trigger="hover">
            <img :src="row.thumbnail" alt="" style="width: 300px">
            <div slot="reference" @click="openGoodsDetail(row)">
              <img :src="row.thumbnail" class="goods-cover" alt="" />
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="商品名称">
        <template slot-scope="{row}">
          <!-- <div @click="openGoodsDetail(row)" class="ellipsis-multiline">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </div> -->
          <!-- tips悬浮提示 -->
          <el-tooltip placement="top" v-model="row.showTooltip" :open-delay="500" effect="dark"
            :disabled="!row.showTooltip">
            <div slot="content">{{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</div>
            <div @mouseenter="showTips($event, row)" @click="openGoodsDetail(row)" class='ellipsis-multiline'>
              {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="数量" width="191">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="单价" width="191"></el-table-column>
      <el-table-column prop="goods_money" label="金额" width="191">
        <template slot-scope="scope">
          {{ scope.row.goods_money || '————' }}
        </template>
      </el-table-column>
    </el-table>
    <div class="settlement">
      <div style="display: flex;">
        <div style="flex: 1;">
          <div style="float: right;font-weight: 700;line-height: 50px;">
            <span>商品金额：</span>
            <span style="color: #b8741a;font-size: 20px;margin: 0 16px 0 0;">{{ goodsMoney }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="submit">
      <div class="balance">
        <div class="balance-left">
          <span v-if="information.data.length">
            总计订单：{{ cartsInfo.order_num }}个 商品金额总计：{{ cartsInfo.goods_money || '————' }} 运费金额总计：{{ cartsInfo.freight }}
          </span>
        </div>
        <div class="dingdan">
          <div :style="{
            fontSize: '14px',
            opacity: information.data.length ? 1 : 0,
          }" class="order">
            <span>订单金额总计：</span>
            <span style="color: #b8741a;font-size: 20px;">{{ cartsInfo.order_money }}</span>
          </div>
          <div class="zhandian">
            <span>站点账户余额：</span>
            <span style="color: #b8741a;">{{ cartsInfo.balance_account }}</span>
          </div>
        </div>
      </div>
      <div class="button" @click="createCartTrade">
        提交订单
      </div>
    </div>
    <!-- 导入失败提示 -->
    <el-dialog-x-dialog :close-on-click-modal="true" :append-to-body="true" title="导入失败" :visible.sync="isVisible"
      width="320px">
      <div style="display: flex; width: 300px;height: 150px;align-items: center;">
        <div style="width: 50px;">
          <i class="el-icon-error" style="font-size: 50px; color: red;"></i>
        </div>
        <div style="flex: 1;">很抱歉，因导入信息有误，收货人导入失败，请点击下载
          <a href="javascript:;" @click="downDataTemplate">失败原因文件</a>
          ，修改或删除后重新导入
        </div>
      </div>
    </el-dialog-x-dialog>
    <!-- 选择收货人 -->
    <x-dialog :proxy="publishOnlineDialog">
      <div class="selectConsignee">
        <div class="row no-gutters align-items-center ">
          <el-form>
            <div style="display: flex;align-items: center;margin-top: 20px;">
              <el-form-item label="收货人姓名" label-width="100px">
                <el-input placeholder="请输入收货人名称" clearable v-model.trim="addrParams.addr_name">
                </el-input>
              </el-form-item>
              <el-form-item label="手机号" label-width="60px">
                <el-input placeholder="请输入收货人手机号" clearable v-model.trim="addrParams.mobile" :maxlength="11">
                </el-input>
              </el-form-item>
              <div class="col-auto" style="margin-right: 10px;margin-bottom: 22px"><el-button
                  @click.stop.prevent="search" size="small" type="primary" native-type="submit">搜索</el-button></div>
            </div>
          </el-form>
        </div>
        <div class="tables">
          <el-table ref="multipleTable" @select="createGrantTableSelectChange" v-bind="$attrs" v-on="$listeners"
            :height="tableHeight" highlight-current-row @select-all="createGrantTableSelectChangeAll"
            :data="createGranList.data" border :header-cell-style="{
              textAlign: 'center',
              backgroundColor: '#e6ecf7',
              fontWeight: '600',
              color: '#333',
            }" :cell-style="{ textAlign: 'center' }" style="width: 100%" max-height="320">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="收货人姓名" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
            <el-table-column label="所在地区" show-overflow-tooltip>
              <template slot-scope="{row}">
                <span>{{ (row.province || '') + (row.city || '') + (row.county || '') + (row.town || '') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="详细地址" show-overflow-tooltip>
              <template slot-scope="{row}">
                <span>{{ row.addr }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: center; margin-top: 5px;">
          <el-pagination @size-change="handlecreateGrantSizeChange" @current-change="handlecreateGrantCurrentChange"
            :current-page="createGranList.page_no" :page-sizes="MixinPageSizes" :page-size="createGranList.page_size"
            :total="createGranList.data_total" background :layout="MixinTableLayout"></el-pagination>
        </div>
        <div style="margin: 5px 0 5px 0;">
          <el-form>
            <el-form-item label-width="80px" label="订单备注">
              <el-input placeholder="请输入订单备注" v-model="notes" :min="1" :max="200"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </x-dialog>
    <!-- 提交订单错误提示 -->
    <x-dialog :proxy="checkCreateErrstatusDialog">
      <div v-if="checkCreateErrstatus.type === 'down_goods'">
        <p>如下商品已下架，是否剔除并继续提交订单？</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in checkCreateErrstatus.data.down_goods" :key="item.goods_id">{{ item.goods_alias ||
            item.name
            || ''
            }}
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" @click="checkCreateErrstatusDialog.dismiss()">暂不提交</el-button>
            <el-button size="mini" type="primary" @click="filterSubmit">剔除并继续提交</el-button>
          </div>
        </div>
      </div>
      <div v-if="checkCreateErrstatus.type === 'goods_addr'">
        <p>如下商品在所选收货人地址无法配送，请调整后重新提交订单。</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in checkCreateErrstatus.data.goods_addr" :key="item.goods.goods_id"
            style="margin-top: 20px;">
            <div v-for="(good, index) in item.goods" :key="index">
              {{ good.goods_alias || good.name || '' }}
            </div>
            <div style="margin-top: 10px;">以上商品无法配送到如下收货人：</div>
            <div v-for="(addrs, index) in item.member_address" :key="index">
              {{ addrs.name + ' ' + addrs.mobile }}
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" type="primary" @click="checkCreateErrstatusDialog.dismiss()">我知道了</el-button>
          </div>
        </div>
      </div>
      <div v-if="checkCreateErrstatus.type === 'stock'">
        <p>如下商品库存不足，请返回商品选购页确认商品库存，在购物车调整后重新提交订单。</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in checkCreateErrstatus.data.stock" :key="item.goods_id">{{ item.goods_alias || item.name ||
            ''
            }}
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" type="primary" @click="checkCreateErrstatusDialog.dismiss()">我知道了</el-button>
          </div>
        </div>
      </div>
      <div v-if="checkCreateErrstatus.type === 'price'">
        <p>如下商品价格发生变更，请返回商品选购页确认商品价格，在购物车调整后重新提交订单。</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in checkCreateErrstatus.data.price" :key="item.goods_id">{{ item.goods_alias || item.name ||
            ''
            }}
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" type="primary" @click="checkCreateErrstatusDialog.dismiss()">我知道了</el-button>
          </div>
        </div>
      </div>
      <div v-if="checkCreateErrstatus.type === 'partialSuccess'">
        <p>部分订单已提交成功！</p>
        <p>但因如下商品库存不足，部分订单提交失败，请下载生单失败文件调整后补充下单！</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in partialSuccessData.err_order" :key="item.goods_id">{{ item.goods_alias || item.name
            || item.err_msg || ''
            }}
          </div>
        </div>
        <div v-if="partialSuccessData.list && partialSuccessData.list.length" style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" @click="downLoadErrFile">下载生单失败文件</el-button>
            <el-button size="mini" type="primary" @click="confirmPay">继续确认支付</el-button>
          </div>
        </div>
      </div>
    </x-dialog>
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="1150px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <goodsPurchaseclassify v-if="dialogVisible" :formData="goodsclassifyData" :cartNum="cartNum">
      </goodsPurchaseclassify>
    </el-dialog>
    <loading v-if="showLoading" style="padding: 40px 20px;">收货人导入中，请等待...</loading>
  </div>
</template>

<script>
import { getCarts, updateRemark, checkedCarts, addressesImpart, getAddr, createCartTrade } from '@/api/shopGoodsPurchase'
import { handleDownload, downloadErr } from "@/utils";
import UploadExcelComponent from "@/components/UploadExcel";
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import Loading from "@/components/Loading";
import goodsPurchaseclassify from "@/components/GoodsClassify/goodsPurchaseclassify";
import { color } from 'echarts';
const cellStyle = ({ row, column, rowIndex, columnIndex }) => columnIndex === 1 ? '' : 'cellStyle'
const add = (arg1, arg2) => {
  var r1, r2, max;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  max = Math.pow(10, Math.max(r1, r2))
  return (arg1 * max + arg2 * max) / max
}
// 获取年月日时分秒
const getDate = () => { // 获取当前时间
  const addZeroForNum = (num) => {
    return num < 10 ? '0' + num : num
  }
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours(); // 获取当前小时数(0-23)
  const minute = date.getMinutes(); // 获取当前分钟数(0-59)
  const second = date.getSeconds(); // 获取当前秒数(0-59)

  const time = `${year}${addZeroForNum(month)}${addZeroForNum(day)}${addZeroForNum(hour)}${addZeroForNum(minute)}${addZeroForNum(second)}`;
  return time;
}
export default {
  name: 'ManagerOneClickDeliverySettlementInformation',
  components: { UploadExcelComponent, XDialog, Loading, goodsPurchaseclassify },
  data () {
    return {
      tableHeight: null,
      dialogVisible: false,
      goodsclassifyData: {},
      showLoading: false,
      goodsMoney: '',
      tableData: [],
      partialSuccessData: {},//部分提交成功
      cartsInfo: {},
      isVisible: false,
      createGranList: { // 收货人弹窗
        data: [],
      },
      notes: '',
      information: { // 收货人
        data: []
      },
      selectInfo: [],// 选择的收货人信息
      listSelectInfo: [], // 列表选择的收货人信息
      checkCreateErrstatus: {
        data: {},
        type: null
      },
      addrParams: {
        page_no: 1,
        page_size: 20,
        addr_name: undefined, // 收货人姓名
        mobile: undefined, // 收货人手机号
      },
      publishOnlineDialog: $xDialog.create({
        title: "选择收货人",
        width: "70vw",
        afterDismiss: () => {
          this.notes = ''
          this.selectInfo = []
        },
        beforeConfirm: () => {
          this.information.data.push(...this.selectInfo)
          if (this.notes) this.information.data.forEach(item => item.order_remark = this.notes)
          if (this.notes) updateRemark({ ids: this.information.data.map(item => item.addr_id).join(','), remark: this.notes })
          this.getCarts()
          this.selectInfo = []
        }
      }),
      checkCreateErrstatusDialog: $xDialog.create({
        title: "提示",
        width: "480px",
        disableConfirm: true,
        disableCancel: true,
        afterDismiss: () => {
        },
        beforeConfirm: () => {
        }
      }),
      resultsTmp: []
    };
  },
  mounted () {
    this.getCarts()
    this.getAddr()
  },
  methods: {
    showTips (obj, row) {
      /*obj为鼠标移入时的事件对象*/

      /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
      let TemporaryTag = document.createElement('span');
      TemporaryTag.innerText = row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
      TemporaryTag.className = 'getTextWidth';
      document.querySelector('body').appendChild(TemporaryTag);
      let currentWidth = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();

      /*cellWidth为表格容器的宽度*/
      const cellWidth = obj.target.offsetWidth

      /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
      currentWidth <= (2 * cellWidth) ? row.showTooltip = false : row.showTooltip = true
    },
    openGoodsDetail (row) {
      this.dialogVisible = true
      console.log(row);
      this.goodsclassifyData = {
        from: "",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        shop_profit: row.shop_profit,
        islogin: this.MixinIsFormEnterprise,//是否单独登录
        distribution_rate: row.distribution_rate,
        sku_id: row.sku_id,
        addCarts: this.addCarts,
      }
    },
    /**获取购物车列表 */
    async getCarts () {
      const addr_num = this.information.data.length
      console.log(addr_num, 'addr_num');
      const res = await getCarts(addr_num ? { addr_num } : undefined)
      if (!res?.data?.list) this.cartNum = 0
      else this.cartNum = res?.data?.list?.[0]?.all_list?.reduce((a, b) => a + b.num, 0) ?? 0
      this.cartsInfo = res.data
      const list = res?.data?.list?.[0]?.all_list ?? []
      this.tableData = list.filter(item => item.checked)
      this.goodsMoney = res?.data?.list?.[0]?.money ?? ''
    },
    downTemplate () {
      // 下载模板
      let tHeaders = [], filterVals = [];
      tHeaders = ["收货人姓名", "手机号", '详细地址', '订单备注'];
      handleDownload(
        [],
        tHeaders,
        filterVals,
        "收货人导入模板"
      );
    },
    async excelSuccess ({ results }) {
      try {
        const tHeaders = ["收货人姓名", "手机号", '详细地址', '订单备注']
        if (!Array.isArray(results) || !results.length) return this.$message.error('请勿导入空模板数据')
        const tmpHName = Object.entries(results[0]).map(([k]) => k)
        const emptyData = tHeaders.slice(0, tHeaders.length - 1).filter(item => !tmpHName.includes(item))
        // if (emptyData.length) return this.$message.error(`${emptyData.join(',')} 列数据为空，请检查模板数据是否正确`)
        if (emptyData.length) return this.$message.error(`存在必填项未填写，请进行填写`)
        const isRight = tmpHName.every((item, index) => item === tHeaders[index])
        if (!isRight) return this.$message.error('模板不正确，请下载模板重新上传')
        if (results.length > 200) return this.$message.error('导入失败，一次最多可导入收货人行数200条，请进行删减后重试')
        const resultsTmp = this.handleResults(results)
        this.showLoading = true
        this.resultsTmp = resultsTmp
        const res = await addressesImpart(resultsTmp)
        this.showLoading = false
        if (res.code !== 200) return this.isVisible = true, this.downList = res.data
        await this.getImportAddrs(res.data)
        this.getCarts()
        this.$message.success('收货人地址导入成功')
      } catch (error) {
        this.showLoading = false
      }
    },
    /**获取导入的收货人 */
    async getImportAddrs (data) {
      // const addr_ids = this.createGranList.data.map(item => item.addr_id)
      // const res = await getAddr(this.addrParams)
      // const ImportAddrs = res.data?.data?.filter(item => !addr_ids.includes(item.addr_id)) ?? []
      const addr_ids = this.information.data.map(item => item.addr_id)
      const ImportAddrs = data.map(item => Object.assign(item, item.member_address, { name: item.addr_name })).filter(item => !addr_ids.includes(item.addr_id))
      const updateAddrs = data.map(item => Object.assign(item, item.member_address, { name: item.addr_name })).filter(item => addr_ids.includes(item.addr_id))
      if (updateAddrs.length) {
        updateAddrs.forEach(item => {
          const index = this.information.data.findIndex(info => info.addr_id === item.addr_id)
          if (index > -1) this.information.data.splice(index, 1, item)
        })
      }
      this.information.data.push(...ImportAddrs)
    },
    handleResults (results) {
      if (!Array.isArray(results)) return
      const tHeaders = { "收货人姓名": 'addr_name', "手机号": 'mobile', '详细地址': 'addr', '订单备注': 'order_remark' }
      return results.map(item => Object.fromEntries(Object.entries(item).map((item) => {
        item[0] = tHeaders[item[0]]
        return item
      })))
    },
    downDataTemplate () {
      const getId = (item) => (item?.addr_name || '') + (item?.mobile || '') + (item?.addr || '')

      const tHeaders = ['addr_name', 'mobile', 'addr', 'order_remark', 'error_msg']
      const resultsTmp = [...this.resultsTmp]
      // const badList = this.downList.map(item => tHeaders.map(head => item[head]))
      const badList = [...this.downList]
      const flagList = resultsTmp.map(item => getId(item))
      const badFlagList = badList.map(item => getId(item))
      flagList.forEach((item, index) => {
        if (badFlagList.includes(item)) {
          const fIndex = badFlagList.findIndex(i => i === item)
          resultsTmp[index] = badList[fIndex]
        }

      })

      downloadErr(["收货人姓名", "手机号", '详细地址', '订单备注', '失败原因'], resultsTmp.map(item => tHeaders.map(head => item[head])), [],
        getDate())
    },
    batchDelete () {
      if (!this.listSelectInfo.length) return this.$message.error('请选择要删除的收货人')
      this.listSelectInfo.forEach(row => {
        this.information.data.splice(this.information.data.findIndex(item => item === row), 1)
      })
      this.getCarts()
    },
    detailAddr (row) {
      this.information.data.splice(this.information.data.findIndex(item => item === row), 1)
      this.getCarts()
    },
    search () {
      this.getAddr()
    },
    async getAddr () {
      const res = await getAddr(this.addrParams)
      this.createGranList = res.data
      this.toggleSelection()
    },
    /**列表选中收货人姓名 */
    selectChange (e) {
      this.listSelectInfo = e
    },
    /** 弹框选中收货人姓名 */
    createGrantTableSelectChange (e, row) {
      const targrt = e.find(item => item === row)
      if (targrt) {
        const addr_ids = this.information.data.map(item => item.addr_id).concat(this.selectInfo.map(item => item.addr_id))
        const results = e.filter(item => !addr_ids.includes(item.addr_id))
        this.selectInfo.push(...results)
      } else {
        const index = this.selectInfo.findIndex(item => item.addr_id === row.addr_id)
        if (index != -1) this.selectInfo.splice(index, 1)
      }
    },
    createGrantTableSelectChangeAll (e, item) {
      // 全选
      if (e.length) {
        const addr_ids = this.information.data.map(item => item.addr_id).concat(this.selectInfo.map(item => item.addr_id))
        const results = e.filter(item => !addr_ids.includes(item.addr_id))
        this.selectInfo.push(...results)
      } else if (!e.length) {
        // 取消全选
        const addr_ids = this.createGranList.data.map(item => item.addr_id)
        addr_ids.forEach(item => {
          this.selectInfo.splice(this.selectInfo.findIndex(info => item === info.addr_id), 1)
        })

      }
    },
    /** 回显收货人 */
    async toggleSelection () {
      const addr_ids = this.information.data.map(item => item.addr_id).concat(this.selectInfo.map(item => item.addr_id))
      const selectInfo = this.createGranList.data.filter(item => addr_ids.includes(item.addr_id))
      setTimeout(() => {
        const multipleTable = this.$refs.multipleTable
        selectInfo.forEach(row => {
          multipleTable.toggleRowSelection(row);
        })
      }, 50)
    },
    /**收货人姓名单页数量改变 */
    handlecreateGrantSizeChange (size) {
      this.addrParams.page_size = size;
      this.getAddr()
    },
    /**收货人姓名页数改变 */
    handlecreateGrantCurrentChange (page) {
      this.addrParams.page_no = page;
      this.getAddr()
    },
    /**提交订单 */
    async createCartTrade () {
      if (!this.information.data.length) return this.$message.error('请选择收货人')
      const addr_ids = this.information.data.map(item => item.addr_id)
      const res = await createCartTrade({ addr_ids })
      // if (res && res.code === -1) return this.$message.error(res.message)
      if (res && res.code === -1) return this.checkCreateErr(res.data)
      if (res?.err_order?.length || res?.data?.err_order?.length) return this.handlePartialSuccess(res.data)
      this.$emit('getBatch_sn', res?.data?.batch_no)
      this.$emit('goNext')
    },
    /**检查提交订单类型 */
    checkCreateErr (data) {

      // 下架商品。downGoods
      // 库存不够  stock
      // 不可售    check
      // 价格变更  price
      // 校验当前列表商品是否存在(删除)，或是否状态为上架状态，
      this.checkCreateErrstatus.data = data
      // 不可能配置的商品对应地址   goodsAddr
      if (data?.down_goods?.length) {
        this.checkCreateErrstatus.type = 'down_goods'
      }
      // 校验当前列表商品是否在已选收货地址可配送
      if (data?.goods_addr?.length) {
        const set = new Set()
        this.checkCreateErrstatus.type = 'goods_addr'
        const goods_addr = this.checkCreateErrstatus.data.goods_addr
        const newGoodsAddr = [], resGoodsAddr = []
        // 如果有重复的商品 则添加到新数组中
        goods_addr.forEach(item => {
          const index = newGoodsAddr.findIndex(info => info?.goods?.goods_id === item?.goods?.goods_id)
          if (index === -1) newGoodsAddr.push({ goods: item.goods, member_address: [item.member_address] })
          else newGoodsAddr[index].member_address.push(item.member_address)
        })

        // 处理地址一样的问题
        newGoodsAddr.forEach(item => {
          item.mapAddr_id = item.member_address.map(item => item.addr_id).sort((a, b) => a - b).join(',')
          if (!Array.isArray(item.goods)) item.goods = [item.goods]
          if (set.has(item.mapAddr_id)) {
            const index = resGoodsAddr.findIndex(info => info.mapAddr_id === item.mapAddr_id)
            if (index === -1) resGoodsAddr.push(item)
            newGoodsAddr[index].goods.push(...item.goods)
          } else {
            set.add(item.mapAddr_id)
            resGoodsAddr.push(item)
          }
        })

        console.log(resGoodsAddr);
        this.checkCreateErrstatus.data.goods_addr = resGoodsAddr
      }
      // 校验当前列表所有商品库存是否足够
      else if (data?.stock?.length) {
        this.checkCreateErrstatus.type = 'stock'
      } else if (data?.price?.length) {
        this.checkCreateErrstatus.type = 'price'
      }

      console.log('check', this.checkCreateErrstatus, data);
      // 生单时库存不足兜底逻辑
      // if (data?.goods_addr?.length) {
      //   this.checkCreateErrstatus.type = 'goods_addr'
      // }
      this.checkCreateErrstatusDialog.display()
    },
    /**剔除并继续交易 */
    async filterSubmit () {
      this.checkCreateErrstatus.data
      const sku_ids = this.checkCreateErrstatus?.data?.down_goods?.map(item => item.sku_id) ?? []
      await checkedCarts({ sku_ids, checked: 0 /**0取消 1勾选 */ })
      this.createCartTrade()
    },
    /**部分订单提交成功 */
    handlePartialSuccess (data) {
      this.partialSuccessData = data
      this.checkCreateErrstatus.type = 'partialSuccess'
      this.checkCreateErrstatusDialog.display()
    },
    /**下载圣单失败文件 */
    downLoadErrFile () {
      const tHeaders = ['good_sn', 'num', 'addr_name', 'mobile', 'addr', 'order_remark', 'error_msg']
      downloadErr(["商品编号", "数量", "收货人姓名", "手机号", '详细地址', '订单备注', '失败原因'], this.checkCreateErrstatus.data.goods_addr.map(item => tHeaders.map(head => item[head])), [],
        getDate())
    },
    /**继续确认支付 */
    async confirmPay () {
      const res = await payTrade({ batch_sn: this.partialSuccessData.batch_no })
      if (res.code === -1) {
        try {
          await this.$confirm(res.message, '提示', { type: 'warning', confirmButtonText: '立即充值', cancelButtonText: '暂不充值', })
          this.$router.push({ path: '/finance/shop-overview' })
        } catch (error) {

        }
        return
      }
      if (res.code === 200) {
        if (res?.err_order?.length) {
          try {
            await this.$confirm(res.message, '提示', { type: 'success', confirmButtonText: '查看订单', cancelButtonText: '立即充值', })
            this.$router.push({ path: '/order/order-list' })
          } catch (error) {
            this.$router.push({ path: '/finance/shop-overview' })
          }
        } else {
          try {
            await this.$confirm(res.message, '提示', { type: 'success', confirmButtonText: '查看订单', cancelButtonText: '继续选购', })
            this.$router.push({ path: '/order/order-list' })
          } catch (error) {
            this.$router.push({ path: '/shop-goods/shop-goods-purchase' })
          }
        }

        return
      }
    },
    cellStyle,
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  /deep/ .x-dialog {
    .el-dialog__body {
      .el-scrollbar {
        .is-vertical {
          display: none;
        }
      }
    }
  }


}

.selectConsignee {
  /deep/ .el-form-item {
    margin-bottom: 21px;
  }
}

.goods-cover-wrapper {
  padding: 0;

  .cell {
    padding: 0;
    text-align: center;

    span,
    a {
      display: inline-block;
    }
  }

  .goods-cover {
    width: 52px;
    height: 52px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
}

.settlement {
  height: 50px;
  border: 1px solid #ebeef5;
  border-top: 0;

}

.submit {
  height: 61px;
  margin-top: 8px;
  display: flex;

  .balance {
    flex: 1;
    display: flex;
    justify-content: space-between;

    &-left {
      line-height: 61px;
    }

    .dingdan {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .order {
      margin-right: 10px;
    }

    .zhandian {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-right: 10px;
      font-weight: 400;
    }
  }

  .button {
    width: 152px;
    height: 100%;
    font-size: 16px;
    margin-right: 40px;
    line-height: 61px;
    text-align: center;
    background-color: #b8741a;
    color: #fff;
    cursor: pointer;
  }
}

@media screen and (min-height: 800px) {
  .selectConsignee {
    --open-height: 324px;
  }
}

@media screen and (max-height: 799px) {
  .selectConsignee {
    --open-height: 275px;
  }
}
</style>